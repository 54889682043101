/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { colors } from '@material-ui/core';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';

import PagesRoundedIcon from '@material-ui/icons/PagesRounded';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import EcoRoundedIcon from '@material-ui/icons/EcoRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';

import StorageRoundedIcon from '@material-ui/icons/StorageRounded';
import PieChartRoundedIcon from '@material-ui/icons/PieChartRounded';

import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import MovieOutlinedIcon from '@material-ui/icons/MovieOutlined';

import SupervisorAccountRoundedIcon from '@material-ui/icons/SupervisorAccountRounded';

import Label from 'src/components/Label';

export default [
	{
		subheader: 'nav.admin.default',
		items: [
			{
				id: 'overview',
				title: 'nav.admin.overview',
				href: '/overview',
				icon: HomeRoundedIcon,
				label: () => <Label color={colors.green['300']}>Live</Label>
			},
			{
				id: 'pages',
				title: 'nav.pages',
				href: '/pages',
				icon: PagesRoundedIcon
			},
			{
				id: 'news',
				title: 'nav.news',
				href: '/news',
				icon: AnnouncementOutlinedIcon
			},
			{
				id: 'survey',
				title: 'nav.survey.default',
				href: '/survey',
				icon: AssignmentRoundedIcon,
				items: [
					{
						title: 'nav.survey.surveys',
						href: '/survey'
					},
					{
						title: 'nav.survey.scores',
						href: '/survey/scores'
					},
				]
			},
		],
	},
	{
		subheader: 'nav.data.default',
		items: [
			{
				id: 'data_datasets',
				title: 'nav.data.dataset.default',
				href: '/data/dataset',
				icon: StorageRoundedIcon,
				items: [
					{
						title: 'nav.data.dataset.categories',
						href: '/data/dataset/categories'
					},
					{
						title: 'nav.data.dataset.contents',
						href: '/data/datasets'
					},
				]
			},
			{
				id: 'data_datasets_harvest',
				title: 'nav.data.dataset.harvest',
				href: '/data/dataset/harvest',
				icon: EcoRoundedIcon,
				label: () => <Label color={colors.blue['200']}>Admin</Label>
			},
			{
				id: 'data_dashboards',
				title: 'nav.data.dashboard.default',
				href: '/data/dashboard',
				icon: PieChartRoundedIcon,
				items: [
					{
						title: 'nav.data.dashboard.categories',
						href: '/data/dashboard/categories',
					},
					{
						title: 'nav.data.dashboard.contents',
						href: '/data/dashboards',
					}
				]
			},
		]
	},
	{
		subheader: 'nav.content.default',
		items: [
			{
				id: 'content_articles',
				title: 'nav.content.article.default',
				href: '/content/article',
				icon: BookOutlinedIcon,
				items: [
					{
						title: 'nav.content.article.categories',
						href: '/content/article/categories'
					},
					{
						title: 'nav.content.article.contents',
						href: '/content/articles'
					}
				]
			},
			{
				id: 'content_services',
				title: 'nav.content.service.default',
				href: '/content/service',
				icon: MovieOutlinedIcon,
				items: [
					{
						title: 'nav.content.service.internal',
						href: '/content/service/internal',
					},
					{
						title: 'nav.content.service.external',
						href: '/content/service/external',
					}
				]
			},
		]
	},
	{
		subheader: 'nav.user.default',
		items: [
			{
				id: 'user',
				title: 'nav.user.user.default',
				href: '/user',
				icon: SupervisorAccountRoundedIcon,
				items: [
					{
						title: 'nav.user.user.groups',
						href: '/user/groups'
					},
					{
						title: 'nav.user.user.members',
						href: '/users'
					},
				]
			},
		]
	},
];
