import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000';

export default {
	primary: {
		contrastText: white,
		dark: '#3c6774',
		main: '#5794a7',
		light: '#78a9b8',
	},
	secondary: {
		contrastText: black,
		// dark: '#b28900',
		// main: '#ffc400',
		// light: '#ffcf33'
		dark: '#14a37f',
		main: '#1de9b6',
		light: '#4aedc4'
	},
	error: {
		contrastText: white,
		dark: colors.red[900],
		main: colors.red[600],
		light: colors.red[400]
	},
	text: {
		primary: colors.blueGrey[900],
		secondary: colors.blueGrey[600],
		link: colors.blue[600]
	},
	link: colors.blue[800],
	icon: colors.blueGrey[600],
	background: {
		default: '#d7d9da',//'#F4F6F8',
		paper: white
	},
	divider: colors.grey[200]
};
