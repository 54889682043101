import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import OverviewView from './views/Overview';

export default [
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/overview" />
	},
	{
		path: '/auth',
		component: AuthLayout,
		routes: [
			{
				path: '/auth/login',
				exact: true,
				component: lazy(() => import('src/views/Login'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	},
	{
		path: '/errors',
		component: ErrorLayout,
		routes: [
			{
				path: '/errors/error-401',
				exact: true,
				component: lazy(() => import('src/views/Error401'))
			},
			{
				path: '/errors/error-404',
				exact: true,
				component: lazy(() => import('src/views/Error404'))
			},
			{
				path: '/errors/error-500',
				exact: true,
				component: lazy(() => import('src/views/Error500'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	},
	{
		path: '*',
		component: DashboardLayout,
		routes: [
			{
				path: '/overview',
				exact: true,
				component: OverviewView
			},
			{
				path: '/pages',
				exact: true,
				component: lazy(() => import('src/views/Pages/PageList'))
			},
			{
				path: '/pages/:id',
				exact: true,
				component: lazy(() => import('src/views/Pages/PageDetails'))
			},
			{
				path: '/news',
				exact: true,
				component: lazy(() => import('src/views/News/NewsList'))
			},
			{
				path: '/news/:id',
				exact: true,
				component: lazy(() => import('src/views/News/NewsDetails'))
			},
			{
				path: '/survey',
				exact: true,
				component: lazy(() => import('src/views/Survey/DataList'))
			},
			{
				path: '/survey/scores',
				exact: true,
				component: lazy(() => import('src/views/Survey/ScoreList'))
			},
			{
				path: '/data/dataset/categories',
				exact: true,
				component: lazy(() => import('src/views/Datasets/CategoryList'))
			},
			{
				path: '/data/dataset/categories/:id',
				exact: true,
				component: lazy(() => import('src/views/Datasets/CategoryDetails'))
			},
			{
				path: '/data/datasets',
				exact: true,
				component: lazy(() => import('src/views/Datasets/DataList'))
			},
			{
				path: '/data/dataset/harvest',
				exact: true,
				component: lazy(() => import('src/views/Datasets/HarvestDetails'))
			},
			{
				path: '/data/datasets/:id',
				exact: true,
				component: lazy(() => import('src/views/Datasets/DataDetails'))
			},
			{
				path: '/data/dashboard/categories',
				exact: true,
				component: lazy(() => import('src/views/Dashboards/CategoryList'))
			},
			{
				path: '/data/dashboard/categories/:id',
				exact: true,
				component: lazy(() => import('src/views/Dashboards/CategoryDetails'))
			},
			{
				path: '/data/dashboards',
				exact: true,
				component: lazy(() => import('src/views/Dashboards/DataList'))
			},
			{
				path: '/data/dashboards/:id',
				exact: true,
				component: lazy(() => import('src/views/Dashboards/DataDetails'))
			},
			{
				path: '/data/survey/:id',
				exact: true,
				component: lazy(() => import('src/views/Survey/DataDetails'))
			},
			{
				path: '/content/article/categories',
				exact: true,
				component: lazy(() => import('src/views/Contents/ArticleCategoryList'))
			},
			{
				path: '/content/article/categories/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/ArticleCategoryDetails'))
			},
			{
				path: '/content/articles',
				exact: true,
				component: lazy(() => import('src/views/Contents/ArticleList'))
			},
			{
				path: '/content/articles/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/ArticleDetails'))
			},
			{
				path: '/content/service/internal',
				exact: true,
				component: lazy(() => import('src/views/Contents/InternalServiceList'))
			},
			{
				path: '/content/service/internal/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/InternalServiceDetails'))
			},
			{
				path: '/content/service/external',
				exact: true,
				component: lazy(() => import('src/views/Contents/ExternalServiceList'))
			},
			{
				path: '/content/service/external/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/ExternalServiceDetails'))
			},
			{
				path: '/user/groups',
				exact: true,
				component: lazy(() => import('src/views/Users/UserGroupList'))
			},
			{
				path: '/user/groups/:id',
				exact: true,
				component: lazy(() => import('src/views/Users/UserGroupDetails'))
			},
			{
				path: '/users',
				exact: true,
				component: lazy(() => import('src/views/Users/UserList'))
			},
			{
				path: '/user/:id',
				exact: true,
				component: lazy(() => import('src/views/Users/UserProfile'))
			},
			{
				path: '/user/:id/:tab',
				exact: true,
				component: lazy(() => import('src/views/Users/UserProfile'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	}
];
