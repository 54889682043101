import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
	Box,
	Typography
} from '@material-ui/core';

import Loading from './Loading';
import Results from './Results';

import Conference from 'src/illustrations/Conference';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
	},
	noResults: {
		marginTop: theme.spacing(4)
	},
	image: {
		marginBottom: theme.spacing(4),
	}
}));

const DashboardBoard = () => {
	const classes = useStyles();
	const [objects, setObjects] = useState(null);
	const [total, setTotal] = useState(0);
	const [values, setValues] = useState({
		search: '',
		category_id: -1,
		page: 0,
		sort_by: 'created_date',
		sort_order: 'desc',
		rows_per_page: 5
	});

	//	Functions

	useEffect(() => {
		let mounted = true;

		const newValues = {
			...values,
		};
		const fetchObjects = () => {
			axios.get(WebAPI.DASHBOARDS,
				{ params: newValues })
				.then((response) => {
					if (mounted) {
						const { data } = response;
						if (data.success) {
							setObjects(data.data.items);
							setTotal(data.data.total_rows);
						}
					}
				});
		};
		fetchObjects();

		return () => {
			mounted = false;
		};
	}, [values]);

	const handleSorting = (sortOrder, sortBy) => {
		setValues({
			...values,
			sort_by: sortBy,
			sort_order: sortOrder
		});
	};

	return (
		<div
			className={classes.root}
		>
			{!objects && (
				<Loading />
			)}
			{objects && objects.length === 0 && (
				<Box className={classes.noResults}>
					<div className={classes.image}>
						<Conference />
					</div>
					<Typography align="center" variant="h4" gutterBottom>
						<strong>{locale.t('data.dashboard.contents.no_data')}</strong>
					</Typography>
				</Box>
			)}
			{objects && objects.length > 0 && (
				<Results
					className={classes.results}
					objects={objects}
					onSorting={handleSorting}
					rows={values.rows_per_page}
					total={total}
				/>
			)}
		</div>
	);
};

export default DashboardBoard;
