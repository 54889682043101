import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid, colors } from '@material-ui/core';
import Label from 'src/components/Label';

import NumberFormat from 'react-number-format';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {},
	content: {
		padding: 0,
	},
	item: {
		padding: theme.spacing(3),
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			'&:not(:last-of-type)': {
				borderRight: `1px solid ${theme.palette.divider}`,
			},
		},
		[theme.breakpoints.down('sm')]: {
			'&:not(:last-of-type)': {
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
		},
	},
	titleWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	label: {
		marginLeft: theme.spacing(1),
	},
	overline: {
		marginTop: theme.spacing(1),
	},
	link: {
		display: 'block'
	}
}));

function Statistics({ dashboardInfo, className, ...rest }) {
	const classes = useStyles();

	const [object, setObject] = useState(null);

	//	Functions

	useEffect(() => {
		let mounted = true;

		const fetchObjects = () => {
			axios.get(WebAPI.OVERVIEW_DATASETS)
				.then((response) => {
					if (mounted) {
						const { data } = response;
						if (data.success) {
							setObject(data.data);
						}
					}
				});
		};
		fetchObjects();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<Grid alignItems="center" container justify="space-between">
				<Grid className={classes.item} item md={3} sm={6} xs={12}>
					<Typography
						className={classes.link}
						component={RouterLink}
						to="/data/dataset/categories"
						variant="h2"
					>
						{object && <NumberFormat value={object.groups} displayType="text" thousandSeparator />}
						{!object && '-'}
					</Typography>
					<Typography className={classes.overline} variant="overline">
						{locale.t('overview.dataset.groups')}
					</Typography>
				</Grid>
				<Grid className={classes.item} item md={3} sm={6} xs={12}>
					<Typography
						className={classes.link}
						component={RouterLink}
						to="/data/datasets"
						variant="h2"
					>
						{object && <NumberFormat value={object.contents} displayType="text" thousandSeparator />}
						{!object && '-'}
					</Typography>
					<Typography className={classes.overline} variant="overline">
						{locale.t('overview.dataset.contents')}
					</Typography>
				</Grid>
				<Grid className={classes.item} item md={3} sm={6} xs={12}>
					<div className={classes.titleWrapper}>
						<Typography
							variant="h2"
						>
							{object && <NumberFormat value={object.ckan_contents} displayType="text" thousandSeparator />}
							{!object && '-'}
						</Typography>
						<Label className={classes.label} color={colors.teal.A200}>
							Active
						</Label>
					</div>
					<Typography className={classes.overline} variant="overline">
						{locale.t('overview.dataset.ckan_contents')}
					</Typography>
				</Grid>
				<Grid className={classes.item} item md={3} sm={6} xs={12}>
					<div className={classes.titleWrapper}>
						<Typography
							variant="h2"
						>
							{object && <NumberFormat value={object.other_contents} displayType="text" thousandSeparator />}
							{!object && '-'}
						</Typography>
						<Label className={classes.label} color={colors.teal.A200}>
							Active
						</Label>
					</div>
					<Typography className={classes.overline} variant="overline">
						{locale.t('overview.dataset.other_contents')}
					</Typography>
				</Grid>
			</Grid>
		</Card>
	);
}

Statistics.propTypes = {
	className: PropTypes.string,
};

export default Statistics;
