/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	AppBar,
	Avatar,
	Badge,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Toolbar,
	Hidden,
	Input,
	colors,
	Popper,
	Paper,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ClickAwayListener
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/LockOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';

import Label from 'src/components/Label';
import NotificationsPopover from 'src/components/NotificationsPopover';
import AvatarMenuPopover from 'src/components/AvatarMenuPopover';

import InfoSnackbar from 'src/components/Snackbars/InfoSnackbar';

import useInterval from 'src/utils/useInterval';

import moment from 'moment';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

import { updateWebiste, logout } from 'src/actions';

import getInitials from 'src/utils/getInitials';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		boxShadow: 'none',
		'-webkit-app-region': 'drag'
	},
	flexGrow: {
		flexGrow: 1
	},
	label: {
		marginLeft: theme.spacing(1)
	},
	search: {
		backgroundColor: 'rgba(255,255,255, 0.1)',
		borderRadius: 4,
		flexBasis: 300,
		height: 36,
		padding: theme.spacing(0, 2),
		display: 'flex',
		alignItems: 'center'
	},
	searchIcon: {
		marginRight: theme.spacing(2),
		color: 'inherit'
	},
	searchInput: {
		flexGrow: 1,
		color: 'inherit',
		'& input::placeholder': {
			opacity: 1,
			color: 'inherit'
		}
	},
	searchPopper: {
		zIndex: theme.zIndex.appBar + 100
	},
	searchPopperContent: {
		marginTop: theme.spacing(1)
	},
	trialButton: {
		marginLeft: theme.spacing(2),
		color: theme.palette.common.white,
		backgroundColor: '#541b66',
		'&:hover': {
			backgroundColor: colors.green[900]
		}
	},
	trialIcon: {
		marginRight: theme.spacing(1)
	},
	menuButton: {
		marginRight: theme.spacing(1)
	},
	chatButton: {
		marginLeft: theme.spacing(1)
	},
	notificationsButton: {
		marginLeft: theme.spacing(1)
	},
	notificationsBadge: {
		backgroundColor: colors.green[200]
	},
	avatar: {
		width: theme.spacing(4),
		height: theme.spacing(4),
		marginLeft: theme.spacing(1)
	},
	logoutButton: {
		marginLeft: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
		}
	},
	logoutIcon: {
		marginRight: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			marginRight: 0,
		}
	}
}));

const popularSearches = [
	'Devias React Dashboard',
	'Devias',
	'Admin Pannel',
	'Project',
	'Pages'
];

const noti = {
	notifications: [
		{
			id: 1,
			title: 'New order has been received',
			type: 'order',
			created_at: moment().subtract(2, 'hours')
		},
		{
			id: 2,
			title: 'New customer is registered',
			type: 'user',
			created_at: moment().subtract(1, 'day')
		},
		{
			id: 3,
			title: 'Project has been approved',
			type: 'project',
			created_at: moment().subtract(3, 'days')
		},
		{
			id: 4,
			title: 'New feature has been added',
			type: 'feature',
			created_at: moment().subtract(7, 'days')
		}
	]
};

function TopBar({
	onOpenNavBarMobile,
	className,
	...rest
}) {
	const classes = useStyles();
	const history = useHistory();
	const searchRef = useRef(null);
	const dispatch = useDispatch();
	const session = useSelector((state) => state.session);

	const notificationsRef = useRef(null);
	const [openSearchPopover, setOpenSearchPopover] = useState(false);
	const [searchValue, setSearchValue] = useState('');

	const [notifications, setNotifications] = useState([]);
	const [openNotifications, setOpenNotifications] = useState(false);
	const [openChatBar, setOpenChatBar] = useState(false);

	const avatarMenuRef = useRef(null);
	const [openAvatarMenu, setOpenAvatarMenu] = useState(false);

	const [openDialog, setOpenDialog] = useState(false);

	const [lastNotificationId, setLastNotificationId] = useState('');

	const snackbarMessage = 'Notification'; // locale.t('login.login_fail');
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const handleConfirmLogout = () => {
		setOpenDialog(true);
	};

	const handleLogout = () => {
		dispatch(logout());
		history.push('/auth/login');
	};

	const handleNotificationsOpen = () => {
		setOpenNotifications(true);
	};

	const handleNotificationsClose = () => {
		setOpenNotifications(false);
	};

	const handleItemClick = () => {
		setOpenNotifications(false);
		history.push('/orders');
	};

	const handleAvatarMenuOpen = () => {
		setOpenAvatarMenu(true);
	};

	const handleAvatarMenuClose = () => {
		setOpenAvatarMenu(false);
	};

	const handleAvatarItemClick = (code) => {
		if (code === 'logout') {
			setOpenDialog(true);
		}
		setOpenAvatarMenu(false);
	};

	const handleClose = () => {
		setOpenDialog(false);
	};

	const handleSnackbarClose = () => {
		setOpenSnackbar(false);
	};

	useEffect(() => {
		let mounted = true;

		const values = {
			page: 0,
			rowsPerPage: 20,
			sortOrder: 'DESC',
			sortBy: 'created_date'
		};

		// axios.get(WebAPI.NOTIFICATIONS,
		// 	{ params: values })
		// 	.then((response) => {
		// 		if (mounted) {
		// 			const { data } = response;
		// 			if (data.status === 'S') {
		// 				const total = data.data.total_rows;
		// 				const items = data.data.order;
		// 				setNotifications(items);

		// 				//	Get first item id
		// 				if (items.length > 0 && lastNotificationId !== items[0].id) {
		// 					const index = items.findIndex((element) => element.id === lastNotificationId);
		// 					if (index > 0) {
		// 						setOpenSnackbar(true);
		// 					}

		// 					setLastNotificationId(items[0].id);
		// 				}
		// 			}
		// 		}
		// 	});

		return () => {
			mounted = false;
		};
	}, [lastNotificationId]);

	//	Create interval to fetch notifications
	//	Need to seperate function for use interval
	const fetchNotifications = () => {
		const values = {
			page: 0,
			rowsPerPage: 20,
			sortOrder: 'DESC',
			sortBy: 'created_date'
		};

		// axios.get(WebAPI.NOTIFICATIONS,
		// 	{ params: values })
		// 	.then((response) => {
		// 		const { data } = response;
		// 		if (data.status === 'S') {
		// 			const total = data.data.total_rows;
		// 			const items = data.data.order;
		// 			setNotifications(items);

		// 			//	Get first item id
		// 			if (items.length > 0 && lastNotificationId !== items[0].id) {
		// 				const index = items.findIndex((element) => element.id === lastNotificationId);
		// 				if (index > 0) {
		// 					setOpenSnackbar(true);
		// 				}

		// 				setLastNotificationId(items[0].id);
		// 			}
		// 		}
		// 	});
	};

	useInterval(() => {
		// fetchNotifications();
	}, 60000);

	return (
		<AppBar
			{...rest}
			className={clsx(classes.root, className)}
			color="primary"
		>
			<Toolbar>
				<Hidden lgUp>
					<IconButton
						className={classes.menuButton}
						color="inherit"
						onClick={onOpenNavBarMobile}
					>
						<MenuIcon />
					</IconButton>
				</Hidden>
				<RouterLink to="/">
					<img
						alt="Logo"
						src="http://203.154.91.217:7778/uploads/news/nhic_logo.png"
						style={{ height: 44 }}
					/>
				</RouterLink>
				<Label
					className={classes.label}
					color={colors.green[300]}
				>
					V1.0
				</Label>
				<div className={classes.flexGrow} />
				{/* <Hidden mdDown> */}
				{/* <IconButton
					className={classes.notificationsButton}
					color="inherit"
					onClick={handleNotificationsOpen}
					ref={notificationsRef}
				>
					<Badge
						badgeContent={notifications.filter((element) => element.is_read === 'N').length}
						classes={{ badge: classes.notificationsBadge }}
						variant="dot"
					>
						<NotificationsIcon />
					</Badge>
				</IconButton> */}
				<IconButton aria-label="menu">
					<Avatar
						alt={session.user.firstname}
						className={classes.avatar}
						onClick={handleAvatarMenuOpen}
						ref={avatarMenuRef}
						src={session.user.avatar}
					>
						{getInitials(session.user.firstname)}
					</Avatar>
				</IconButton>
				{/* <Button
					className={classes.logoutButton}
					color="inherit"
					onClick={handleConfirmLogout}
				>
					<InputIcon className={classes.logoutIcon} />
					<Hidden mdDown>
						{locale.t('btn.signout')}
					</Hidden>
				</Button> */}

				{/* </Hidden> */}
			</Toolbar>
			<NotificationsPopover
				anchorEl={notificationsRef.current}
				notifications={notifications}
				onClose={handleNotificationsClose}
				onItemClick={handleItemClick}
				open={openNotifications}
			/>
			<AvatarMenuPopover
				anchorEl={avatarMenuRef.current}
				onClose={handleAvatarMenuClose}
				onItemClick={handleAvatarItemClick}
				open={openAvatarMenu}
			/>
			<Dialog
				open={openDialog}
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">{locale.t('topbar.signout.title')}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						{locale.t('topbar.signout.message')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						{locale.t('btn.cancel')}
					</Button>
					<Button onClick={handleLogout} color="primary">
						{locale.t('btn.signout')}
					</Button>
				</DialogActions>
			</Dialog>
			<InfoSnackbar
				message={snackbarMessage}
				onClose={handleSnackbarClose}
				open={openSnackbar}
			/>
		</AppBar>
	);
}

TopBar.propTypes = {
	className: PropTypes.string,
	onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
