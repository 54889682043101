// export const API_URL = 'http://localhost:7777/';
export const API_URL = 'http://203.154.91.217:7778/';
export const ADMIN_API_URL = `${API_URL}admin/`;

//  Admin
export const LOGIN = `${ADMIN_API_URL}auth/login`;
export const REFRESH_TOKEN = `${ADMIN_API_URL}auth/token`;

//  Overview
export const OVERVIEW_DATASETS = `${ADMIN_API_URL}overview/datasets`;

//  PAGES
export const PAGES = `${API_URL}admin/pages`;

//  New
export const NEWS = `${API_URL}admin/news`;
export const UPLOAD_NEWS_IMAGE = `${API_URL}admin/news/upload`;

//  Dataset
export const DATASET_CATEGORIES = `${API_URL}admin/dataset/categories`;
export const UPLOAD_DATASET_CATEGORY_IMAGE = `${API_URL}admin/dataset/categories/upload`;
export const DATASETS = `${API_URL}admin/dataset/contents`;
export const DATASET_CATEGORIES_SYNC = `${API_URL}admin/dataset/categories/sync`;
export const DATASETS_SYNC = `${API_URL}admin/dataset/contents/sync`;

//  Dashboard
export const DASHBOARD_CATEGORIES = `${API_URL}admin/dashboard/categories`;
export const UPLOAD_DASHBOARD_CATEGORY_IMAGE = `${API_URL}admin/dashboard/categories/upload`;
export const DASHBOARDS = `${API_URL}admin/dashboard/contents`;

//  Survey
export const SURVEY = `${API_URL}admin/survey/data`;
export const SURVEY_SUMMARY = `${API_URL}admin/survey/summary`;
export const SURVEY_SEGMENTS = `${API_URL}admin/survey/segments`;
export const SURVEY_SCORES = `${API_URL}admin/survey/scores`;

//  Content
export const CONTENT_CATEGORIES = `${API_URL}admin/content/categories`;
export const CONTENTS = `${API_URL}admin/content/contents`;
export const UPLOAD_CONTENT_IMAGE = `${API_URL}admin/content/contents/upload`;
export const UPLOAD_CONTENT_EDITOR_IMAGE = `${API_URL}admin/content/editors/upload`;
export const UPLOAD_CATEGORIES_IMAGE = `${API_URL}admin/content/categories/upload`;

//  User
export const USER_GROUPS = `${ADMIN_API_URL}user/groups`;
export const USER_TEAMS = `${ADMIN_API_URL}user/teams`;
export const USER_PROFILE = `${ADMIN_API_URL}user/profile`;
export const USER_TEAMS_CHANGE_PASSWORD = `${ADMIN_API_URL}user/teams/password`;
export const USER_ACTIVITY_LOGS = `${ADMIN_API_URL}user/activity/logs`;

//  Common
export const COMMON_ADDRESS = `${API_URL}common/address`;
