import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
	Container,
	Grid
} from '@material-ui/core';

import Page from 'src/components/Page';
import Header from './Header';
import Statistics from './Statistics';
import DashboardBoard from './DashboardBoard';
import TeamBoard from './TeamBoard';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(1.5),
		paddingBottom: theme.spacing(3)
	},
	container: {
		paddingRight: 0,
	},
}));

function Overview() {
	const classes = useStyles();

	return (
		<Page
			className={classes.root}
			title="Overview"
		>
			<Container
				className={classes.container}
				maxWidth="lg"
			>
				<Header />
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						md={12}
						xs={12}
					>
						<Statistics />
					</Grid>
					<Grid
						item
						md={7}
						xs={12}
					>
						<DashboardBoard />
					</Grid>
					<Grid
						item
						md={5}
						xs={12}
					>
						<TeamBoard />
					</Grid>
				</Grid>
			</Container>
		</Page>
	);
}

export default Overview;
