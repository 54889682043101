export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_NAV_MENU = 'UPDATE_NAV_MENU';

export const login = (payload) => (dispatch) => dispatch({
	type: SESSION_LOGIN,
	payload
});

export const logout = () => (dispatch) => dispatch({
	type: SESSION_LOGOUT
});

export const refreshToken = (payload) => (dispatch) => dispatch({
	type: REFRESH_TOKEN,
	payload
});

export const updateProfile = (payload) => (dispatch) => dispatch({
	type: UPDATE_PROFILE,
	payload
});

export const updateNavMenu = (payload) => (dispatch) => dispatch({
	type: UPDATE_NAV_MENU,
	payload
});
