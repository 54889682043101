export default {
	app_name: 'NHIC Administrator',
	btn: {
		yes: 'ใช่',
		no: 'ไม่ใช่',
		ok: 'ตกลง',
		cancel: 'ยกเลิก',
		discard_changes: 'ยกเลิกการเปลี่ยนแปลง',
		save: 'บันทึก',
		close: 'ปิด',
		clear: 'ล้าง',
		applyFilters: 'บันทึกตัวกรอง',
		search: 'ค้นหา',
		delete: 'ลบ',
		signout: 'ออกจากระบบ',
		next: 'ถัดไป',
		previous: 'ก่อนหน้า',
		add: 'เพิ่ม',
		see_all: 'ดูทั้งหมด'
	},
	dialog: {
		title: 'ข้อความ',
		discard_changes_message: 'ยกเลิกการเปลี่ยนแปลงที่เกิดขึ้น?',
		delete_data_message: 'คุณแน่ใจที่จะลบข้อมูลใช่หรือไม่?',
	},
	badge: {
		status: {
			active: 'เปิดสาธารณะ',
			inactive: 'ซ่อนการมองเห็น',
		},
	},
	result_table: {
		filter_action: 'ค้นหาขั้นสูง',
		hint: ' รายการที่พบ หน้า ',
		hint2: ' จาก ',
		rows_per_page: 'แถวต่อหน้า:',
		page_hint: 'จาก',
		page_hint2: 'มากกว่า ',
		loading: 'กำลังเชื่อมต่อข้อมูล...'
	},
	empty_data: {
		desc: 'รายละเอียดการค้นหาของคุณไม่ตรงกับข้อมูลที่มีอยู่ กรุณาลองค้นหาด้วย',
		desc_2: 'ข้อมูลอื่นอีกครั้งหรือหากต้องการรีเซ็ตการค้นหาข้อมูลใหม่, เพียงคลิก',
		btn_reset: 'ตั้งค่าค้นหาข้อมูลใหม่',
		remark: 'เพื่อดำเนินการต่อ',
	},
	nav: {
		admin: {
			default: 'Admin Management',
			overview: 'Overview',
		},
		pages: 'หน้าเว็บเพจ',
		news: 'ข่าวสารและประชาสัมพันธ์',
		survey: {
			default: 'แบบสอบถาม',
			surveys: 'ชุดแบบสอบถาม',
			scores: 'รายงาน'
		},
		data: {
			default: 'Data Catalog',
			dataset: {
				default: 'ชุดข้อมูล',
				categories: 'กลุ่มข้อมูล',
				contents: 'เนื้อหาข้อมูล',
				harvest: 'Harvest'
			},
			dashboard: {
				default: 'แดชบอร์ด',
				categories: 'กลุ่มแดชบอร์ด',
				contents: 'เนื้อหาแดชบอร์ด'
			},
		},
		content: {
			default: 'Articles',
			article: {
				default: 'บทความ',
				categories: 'หมวดหมู่',
				contents: 'คอนเทนต์'
			},
			service: {
				default: 'งานบริการ',
				internal: 'ภายใต้ศูนย์',
				external: 'หน่วยงานที่เกี่ยวข้อง'
			}
		},
		user: {
			default: 'User Management',
			user: {
				default: 'จัดการผู้ดูแลระบบ',
				groups: 'กลุ่ม',
				members: 'ผู้ใช้',
			},
		},
	},
	login: {
		title: 'Online Marketing Platform',
		header: 'เข้าสู่ระบบ',
		sub_header: 'เริ่มใช้งานแพลตฟอร์มของเรา',
		email: 'อีเมล',
		password: 'รหัสผ่าน',
		login: 'เข้าสู่ระบบ',
		register: 'ไม่มีบัญชีใช้งาน?',
		validator: {
			email: '^กรุณากรอกอีเมล์',
			email_invalid: '^อีเมล์ไม่ถูกต้อง',
			password: '^กรุณากรอกรหัสผ่าน'
		},
		login_fail: 'อีเมล์หรือรหัสผ่านไม่ถูกต้อง, ลองอีกครั้ง!'
	},
	topbar: {
		signout: {
			title: 'แน่ใจหรือไม่?',
			message: 'คุณต้องการออกจากระบบการใช้งานหรือไม่ ?'
		}
	},
	notifications: {
		title: 'แจ้งเตือน'
	},
	overview: {
		header: {
			welcome: 'ยินดีต้อนรับ,',
			subtitle: 'เข้าสู่ระบบการใช้งาน NHIC Administrator'
		},
		dataset: {
			groups: 'กลุ่มข้อมูล',
			contents: 'ชุดข้อมูล',
			ckan_contents: 'CKAN Harvest',
			other_contents: 'แหล่งอื่นๆ',
		}
	},
	pages: {
		title: 'รายการหน้าเว็บเพจ',
		header: 'รายการหน้าเว็บเพจ',
		action: 'เพิ่มรายการ',
		search_placeholder: 'ชื่อหน้าเว็บเพจ',
		no_data: 'ไม่พบข้อมูลรายการหน้าเว็บเพจ',
		result_title: 'รายการหน้าเว็บเพจทั้งหมด',
		item_col: 'ชื่อหน้าเว็บเพจ',
		item_col2: 'Actions',
		create: {
			header: 'สร้างข้อมูลหน้าเว็บเพจ',
			save_fail: 'ไม่สามารถสร้างข้อมูลหน้าเว็บเพจได้ กรุณาติดต่อผู้ดูแลระบบ'
		},
		detail: {
			title: 'แก้ไขรายการหน้าเว็บเพจ',
			header: 'จัดการข้อมูลหน้าเว็บเพจ',
			form_header: 'แก้ไขหน้าเว็บเพจ',
			form_sub_header: 'รายละเอียดหน้าเว็บเพจสำหรับแสดงผลบนเว็บไซต์',
			title_: 'ชื่อหน้าเว็บเพจ',
			title_hint: 'จำกัดตัวอักษร %{count}/250',
			is_enabled: 'เปิดเผยต่อสาธารณะ',
			is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงบทความต่อสมาชิกที่ใช้ระบบ',
			content: 'เนื้อหาหน้าเว็บเพจ',
			content_hint: 'กรอกรายละเอียดที่เกี่ยวข้องกับเนื้อหา...',
			save_success: 'บันทึกข้อมูลหน้าเว็บเพจใหม่เรียบร้อยแล้ว',
			validator: {
				title: '^กรุณากรอกชื่อหน้าเว็บเพจ',
			},
		}
	},
	news: {
		title: 'รายการข่าวสารและประชาสัมพันธ์',
		header: 'รายการข่าวสารและประชาสัมพันธ์',
		action: 'เพิ่มรายการ',
		search_placeholder: 'หัวข้อข่าวสารและประชาสัมพันธ์',
		no_data: 'ไม่พบข้อมูลรายการข่าวสารและประชาสัมพันธ์',
		result_title: 'รายการข่าวสารและประชาสัมพันธ์ทั้งหมด',
		item_col: 'หัวข้อข่าวสารและประชาสัมพันธ์',
		item_col2: 'สถานะ',
		item_col3: 'Actions',
		delete_success: 'ลบข้อมูลข่าวสารและประชาสัมพันธ์เรียบร้อยแล้ว',
		create: {
			header: 'สร้างข้อมูลข่าวสารและประชาสัมพันธ์',
			save_fail: 'ไม่สามารถสร้างข้อมูลข่าวสารและประชาสัมพันธ์ได้ กรุณาติดต่อผู้ดูแลระบบ'
		},
		detail: {
			title: 'แก้ไขรายการข่าวสารและประชาสัมพันธ์',
			header: 'จัดการข้อมูลข่าวสารและประชาสัมพันธ์',
			form_header: 'แก้ไขข่าวสารและประชาสัมพันธ์',
			form_sub_header: 'รายละเอียดข่าวสารและประชาสัมพันธ์สำหรับแสดงผลบนเว็บไซต์',
			title_: 'หัวข้อข่าวสารและประชาสัมพันธ์',
			title_hint: 'จำกัดตัวอักษร %{count}/250',
			is_enabled: 'เปิดเผยต่อสาธารณะ',
			is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงบทความต่อสมาชิกที่ใช้ระบบ',
			content: 'เนื้อหาข่าวสารและประชาสัมพันธ์',
			content_hint: 'กรอกรายละเอียดที่เกี่ยวข้องกับเนื้อหา...',
			save_success: 'บันทึกข้อมูลข่าวสารและประชาสัมพันธ์ใหม่เรียบร้อยแล้ว',
			image: {
				header: 'อัพโหลดภาพปก',
				hint: 'คลิกหรือลากไฟล์มาตรงนี้',
				info: 'ขนาดภาพแนะนำ 850x480'
			},
			validator: {
				title: '^กรุณากรอกหัวข้อข่าวสารและประชาสัมพันธ์',
			},
			other_actions: {
				header: 'Other Actions',
				delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
				btn_delete: 'ลบข้อมูลข่าว'
			}
		}
	},
	survey: {
		scores: {
			title: 'รายงานแบบสอบถาม',
			header: 'รายงานแบบสอบถาม',
			search_placeholder: 'ค้นหา',
			search: {
				start_date: 'ตั้งแต่วันที่',
				end_date: 'จนถึง',
				group_by: {
					default: 'สรุปข้อมูล',
					none: 'ไม่ระบุ',
					date: 'ตามช่วงเวลา'
				}
			},
			result_title: 'รายการแบบสอบถามทั้งหมด',
			no_data: 'ไม่พบข้อมูลรายการแบบสอบถาม',
			items: {
				id: 'ID',
				created_date: 'วัน-เวลา',
				age: 'อายุ',
				occupation: 'อาชีพ',
				type: 'การอยู่อาศัยในปัจจุบัน',
				survey_1_sum: 'ผลกระทบจาก Covid-19 (Sum)',
				survey_1_1: 'ด้านวิธีการทำงาน',
				survey_1_2: 'ด้านการเดินทาง',
				survey_1_3: 'ด้านอาชีพ',
				survey_1_4: 'ด้านการอยู่อาศัย',
				survey_1_mean: 'ผลกระทบจาก Covid-19 (Mean)',
				survey_1_sd: 'ผลกระทบจาก Covid-19 (SD)',
				survey_2_sum: 'ความต้องการปรับปรุงที่อยู่อาศัย (Sum)',
				survey_2_1: 'ต้องการพื้นที่ภายในบ้านเพิ่มขึ้น ',
				survey_2_2: 'สมาชิกในครัวเรือนต้องการพื้นที่ส่วนตัว',
				survey_2_3: 'ต้องการห้องน้ำเพิ่มมากขึ้น',
				survey_2_4: 'ต้องการน้ำดื่มและน้ำสะอาด',
				survey_2_5: 'ต้องการพื้นที่ส่วนกลางในชุมชน ',
				survey_2_6: 'ต้องพึ่งพาการช่วยเหลือจากสมาชิกภายในชุมชน',
				survey_2_mean: 'ความต้องการปรับปรุงที่อยู่อาศัย (Mean)',
				survey_2_sd: 'ความต้องการปรับปรุงที่อยู่อาศัย (SD)',
				message: 'ข้อเสนอแนะหรือข้อคิดเห็นอื่นๆ'
			}
		},
		title: 'รายการแบบสอบถาม',
		header: 'รายการแบบสอบถาม',
		search_placeholder: 'ค้นหา',
		search: {
			start_date: 'ตั้งแต่วันที่',
			end_date: 'จนถึง'
		},
		summary: {
			chart_title: 'จำนวนผู้ทำแบบสอบถามตามช่วงเวลา',
			chart_unit: 'จำนวน %{label} คน',
			segment_title: 'กลุ่มอายุผู้ทำแบบสอบถาม',
		},
		result_title: 'รายการแบบสอบถามทั้งหมด',
		no_data: 'ไม่พบข้อมูลรายการแบบสอบถาม',
		item_col: 'รายละเอียด',
		item_col2: 'วันที่ส่งแบบสอบถาม',
		item_col3: 'Actions',
		detail: {
			title: 'รายละเอียดแบบสอบถาม',
			header: 'รายละเอียดแบบสอบถาม',
			section: {
				title: 'ก. ข้อมูลพื้นฐานของผู้ตอบแบบสำรวจ',
				age: '1. อายุ',
				occupation: '2. อาชีพ',
				type: '3. การอยู่อาศัยในปัจจุบัน',
			},
			section_2: {
				title: 'ข. แบบสำรวจ',
				item_col: 'รายการ',
				item_col2: 'คำตอบ',
			},
			section_3: {
				title: 'ค. ข้อเสนอแนะเกี่ยวกับแบบสำรวจ',
				sources: 'ได้รับทราบจากช่องทางใด',
				platforms: 'เข้าใช้งาน Website ผ่านเครื่องมือใด',
			},
			section_4: {
				title: 'ข้อเสนอแนะหรือข้อคิดเห็นอื่นๆ',
			}
		}
	},
	data: {
		dataset: {
			categories: {
				title: 'รายการกลุ่มข้อมูล',
				header: 'รายการกลุ่มข้อมูล',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่อกลุ่มข้อมูล',
				result_title: 'รายการกลุ่มข้อมูลทั้งหมด',
				no_data: 'ไม่พบข้อมูลรายการกลุ่มข้อมูล',
				item_col: 'ชื่อกลุ่มข้อมูล',
				item_col2: 'สถานะ',
				item_col3: 'Actions',
				delete_success: 'ลบข้อมูลกลุ่มข้อมูลเรียบร้อยแล้ว',
				create: {
					header: 'สร้างกลุ่มข้อมูล',
					save_fail: 'ไม่สามารถสร้างกลุ่มข้อมูลได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการกลุ่มข้อมูล',
					header: 'จัดการกลุ่มข้อมูล',
					form_header: 'แก้ไขกลุ่มข้อมูล',
					form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับชุดข้อมูล ซึ่งจะแสดงผลต่อผู้เข้าใช้งานเว็บไซต์เมื่อมีการเปิดเผยต่อสาธารณะ',
					name: 'ชื่อกลุ่มข้อมูล',
					name_hint: 'จำกัดตัวอักษร %{count}/100',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงกลุ่มข้อมูลต่อสมาชิกที่ใช้ระบบ',
					save_success: 'บันทึกการข้อมูลกลุ่มข้อมูลใหม่เรียบร้อยแล้ว',
					image: {
						header: 'อัพโหลดภาพปก',
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 640x480'
					},
					validator: {
						name: '^กรุณากรอกชื่อกลุ่มข้อมูล',
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบกลุ่มข้อมูล'
					}
				}
			},
			contents: {
				title: 'รายการเนื้อหาข้อมูล',
				header: 'รายการเนื้อหาข้อมูล',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่อเนื้อหาข้อมูล',
				filter: {
					section: 'Info',
					category_id: 'กลุ่มข้อมูล',
					all_category_id: 'กลุ่มข้อมูลทั้งหมด',
					type: 'ประเภทข้อมูล',
					all_type: 'ประเภทข้อมูลทั้งหมด',
					type_harvest: 'CKAN Harvest',
					type_others: 'แหล่งอื่นๆ',
				},
				result_title: 'รายการเนื้อหาข้อมูลทั้งหมด',
				no_data: 'ไม่พบข้อมูลรายการเนื้อหาข้อมูล',
				item_col: 'ชื่อเนื้อหาข้อมูล',
				item_col2: 'กลุ่มข้อมูล',
				item_col3: 'สถานะ',
				item_col4: 'Actions',
				delete_success: 'ลบข้อมูลเนื้อหาข้อมูลเรียบร้อยแล้ว',
				create: {
					header: 'สร้างข้อมูลเนื้อหาข้อมูล',
					save_fail: 'ไม่สามารถสร้างข้อมูลเนื้อหาข้อมูลได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการเนื้อหาข้อมูล',
					header: 'จัดการข้อมูลเนื้อหาข้อมูล',
					form_header: 'แก้ไขเนื้อหาข้อมูล',
					form_sub_header: 'รายละเอียดเนื้อหาข้อมูลสำหรับแสดงผลบนเว็บไซต์',
					category_id: 'กลุ่มข้อมูล',
					category_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					title_: 'ชื่อเนื้อหาข้อมูล',
					title_hint: 'จำกัดตัวอักษร %{count}/250',
					description: 'รายละเอียดแบบย่อ',
					description_hint: 'จำกัดตัวอักษร %{count}/250',
					organization: 'ชื่อองค์กร',
					organization_hint: 'จำกัดตัวอักษร %{count}/100',
					media_url: 'Link ภายนอก',
					tags: 'ติดแท็ก',
					tags_hint: 'จำกัดตัวอักษร %{count}/255',
					tags_placeholder: 'Enter keyword and then press "Enter"',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงเนื้อหาข้อมูลต่อสมาชิกที่ใช้ระบบ',
					save_success: 'บันทึกข้อมูลเนื้อหาข้อมูลใหม่เรียบร้อยแล้ว',
					validator: {
						title: '^กรุณากรอกชื่อเนื้อหาข้อมูล',
						description: '^กรุณากรอกรายละเอียดแบบย่อ',
						media_url: '^กรุณากรอก Link ภายนอก',
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลเนื้อหาข้อมูล'
					}
				}
			},
			harvest: {
				title: 'รวบรวมเนื้อหาข้อมูล',
				header: 'รวบรวมเนื้อหาข้อมูล',
				category: {
					header: 'ปรับปรุงกลุ่มข้อมูล',
					sub_header: 'รวบรวมข้อมูลจาก G-Cloud และนำมาปรับปรุงเพิ่อแสดงบนระบบเว็บไซต์ใหม่',
					btn_action: 'เริ่มปรับปรุงข้อมูล'
				},
				content: {
					header: 'ปรับปรุงชุดข้อมูล',
					sub_header: 'รวบรวมข้อมูลจาก G-Cloud และนำมาปรับปรุงเพิ่อแสดงบนระบบเว็บไซต์ใหม่',
					btn_action: 'เริ่มปรับปรุงข้อมูล'
				},
				confirm_message: 'คุณแน่ใจที่จะปรับปรุงข้อมูลใหม่ใช่หรือไม่',
				save_success: 'ปรับปรุงข้อมูลใหม่เรียบร้อยแล้ว',
			},
		},
		dashboard: {
			categories: {
				title: 'รายการกลุ่มแดชบอร์ด',
				header: 'รายการกลุ่มแดชบอร์ด',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่อกลุ่มแดชบอร์ด',
				result_title: 'รายการกลุ่มแดชบอร์ดทั้งหมด',
				no_data: 'ไม่พบข้อมูลรายการกลุ่มแดชบอร์ด',
				item_col: 'ชื่อกลุ่มแดชบอร์ด',
				item_col2: 'สถานะ',
				item_col3: 'Actions',
				delete_success: 'ลบข้อมูลกลุ่มแดชบอร์ดเรียบร้อยแล้ว',
				create: {
					header: 'สร้างกลุ่มแดชบอร์ด',
					save_fail: 'ไม่สามารถสร้างกลุ่มแดชบอร์ดได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการกลุ่มแดชบอร์ด',
					header: 'จัดการกลุ่มแดชบอร์ด',
					form_header: 'แก้ไขกลุ่มแดชบอร์ด',
					form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับชุดข้อมูล ซึ่งจะแสดงผลต่อผู้เข้าใช้งานเว็บไซต์เมื่อมีการเปิดเผยต่อสาธารณะ',
					name: 'ชื่อกลุ่มแดชบอร์ด',
					name_hint: 'จำกัดตัวอักษร %{count}/100',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงกลุ่มแดชบอร์ดต่อสมาชิกที่ใช้ระบบ',
					save_success: 'บันทึกการข้อมูลกลุ่มแดชบอร์ดใหม่เรียบร้อยแล้ว',
					image: {
						header: 'อัพโหลดภาพปก',
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 640x480'
					},
					validator: {
						name: '^กรุณากรอกชื่อกลุ่มแดชบอร์ด',
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบกลุ่มแดชบอร์ด'
					}
				}
			},
			contents: {
				title: 'รายการเนื้อหาแดชบอร์ด',
				header: 'รายการเนื้อหาแดชบอร์ด',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่อเนื้อหาแดชบอร์ด',
				filter: {
					section: 'Info',
					category_id: 'กลุ่มแดชบอร์ด',
					all_category_id: 'กลุ่มแดชบอร์ดทั้งหมด',
				},
				result_title: 'รายการเนื้อหาแดชบอร์ดทั้งหมด',
				no_data: 'ไม่พบข้อมูลรายการเนื้อหาแดชบอร์ด',
				item_col: 'ชื่อเนื้อหาแดชบอร์ด',
				item_col2: 'กลุ่มแดชบอร์ด',
				item_col3: 'สถานะ',
				item_col4: 'Actions',
				delete_success: 'ลบข้อมูลเนื้อหาแดชบอร์ดเรียบร้อยแล้ว',
				create: {
					header: 'สร้างข้อมูลเนื้อหาแดชบอร์ด',
					save_fail: 'ไม่สามารถสร้างข้อมูลเนื้อหาแดชบอร์ดได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการเนื้อหาแดชบอร์ด',
					header: 'จัดการข้อมูลเนื้อหาแดชบอร์ด',
					form_header: 'แก้ไขเนื้อหาแดชบอร์ด',
					form_sub_header: 'รายละเอียดเนื้อหาแดชบอร์ดสำหรับแสดงผลบนเว็บไซต์',
					category_id: 'กลุ่มแดชบอร์ด',
					category_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					title_: 'ชื่อเนื้อหาแดชบอร์ด',
					title_hint: 'จำกัดตัวอักษร %{count}/250',
					description: 'รายละเอียดแบบย่อ',
					description_hint: 'จำกัดตัวอักษร %{count}/250',
					organization: 'ชื่อองค์กร',
					organization_hint: 'จำกัดตัวอักษร %{count}/100',
					media_url: 'Link ภายนอก',
					tags: 'ติดแท็ก',
					tags_hint: 'จำกัดตัวอักษร %{count}/255',
					tags_placeholder: 'Enter keyword and then press "Enter"',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงเนื้อหาแดชบอร์ดต่อสมาชิกที่ใช้ระบบ',
					save_success: 'บันทึกข้อมูลเนื้อหาแดชบอร์ดใหม่เรียบร้อยแล้ว',
					validator: {
						title: '^กรุณากรอกชื่อเนื้อหาแดชบอร์ด',
						description: '^กรุณากรอกรายละเอียดแบบย่อ',
						media_url: '^กรุณากรอก Link ภายนอก',
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลเนื้อหาแดชบอร์ด'
					}
				}
			},
		},
	},
	content: {
		article: {
			categories: {
				title: 'รายการหมวดหมู่',
				header: 'รายการหมวดหมู่',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่อหมวดหมู่',
				result_title: 'รายการหมวดหมู่ทั้งหมด',
				no_data: 'ไม่พบข้อมูลรายการหมวดหมู่',
				item_col: 'ชื่อหมวดหมู่',
				item_col2: 'สถานะ',
				item_col3: 'Actions',
				delete_success: 'ลบข้อมูลหมวดหมู่เรียบร้อยแล้ว',
				create: {
					header: 'สร้างข้อมูลหมวดหมู่',
					save_fail: 'ไม่สามารถสร้างข้อมูลหมวดหมู่ได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการหมวดหมู่',
					header: 'จัดการข้อมูลหมวดหมู่',
					form_header: 'แก้ไขหมวดหมู่',
					form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับบทความ ซึ่งจะแสดงผลต่อผู้เข้าใช้งานเว็บไซต์เมื่อมีการเปิดเผยต่อสาธารณะ',
					name: 'ชื่อหมวดหมู่',
					name_hint: 'จำกัดตัวอักษร %{count}/100',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงหมวดหมู่ต่อสมาชิกที่ใช้ระบบ',
					save_success: 'บันทึกการข้อมูลหมวดหมู่ใหม่เรียบร้อยแล้ว',
					validator: {
						name: '^กรุณากรอกชื่อหมวดหมู่',
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลหมวดหมู่'
					}
				}
			},
			contents: {
				title: 'รายการบทความ',
				header: 'รายการบทความ',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่อบทความ',
				filter: {
					section: 'Info',
					category_id: 'หมวดหมู่',
					all_category_id: 'หมวดหมู่ทั้งหมด',
				},
				result_title: 'รายการบทความทั้งหมด',
				no_data: 'ไม่พบข้อมูลรายการบทความ',
				item_col: 'ชื่อบทความ',
				item_col2: 'หมวดหมู่',
				item_col3: 'สถานะ',
				item_col4: 'Actions',
				delete_success: 'ลบข้อมูลบทความเรียบร้อยแล้ว',
				create: {
					header: 'สร้างข้อมูลบทความ',
					save_fail: 'ไม่สามารถสร้างข้อมูลบทความได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการบทความ',
					header: 'จัดการข้อมูลบทความ',
					form_header: 'แก้ไขบทความ',
					form_sub_header: 'รายละเอียดบทความสำหรับแสดงผลบนเว็บไซต์',
					category_id: 'หมวดหมู่บทความ',
					category_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					title_: 'ชื่อบทความ',
					title_hint: 'จำกัดตัวอักษร %{count}/250',
					description: 'รายละเอียดแบบย่อ',
					description_hint: 'จำกัดตัวอักษร %{count}/250',
					organization: 'ชื่อองค์กร',
					organization_hint: 'จำกัดตัวอักษร %{count}/100',
					media_url: 'Link ภายนอก',
					tags: 'ติดแท็ก',
					tags_hint: 'จำกัดตัวอักษร %{count}/255',
					tags_placeholder: 'Enter keyword and then press "Enter"',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงบทความต่อสมาชิกที่ใช้ระบบ',
					content: 'เนื้อหาบทความ',
					content_hint: 'กรอกรายละเอียดที่เกี่ยวข้องกับเนื้อหา...',
					save_success: 'บันทึกข้อมูลบทความใหม่เรียบร้อยแล้ว',
					image: {
						header: 'อัพโหลดภาพปก',
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 850x480'
					},
					validator: {
						title: '^กรุณากรอกชื่อบทความ',
						description: '^กรุณากรอกรายละเอียดแบบย่อ',
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลบทความ'
					}
				}
			},
		},
		internal_service: {
			contents: {
				title: 'รายการงานบริการภายใต้ศูนย์',
				header: 'รายการงานบริการภายใต้ศูนย์',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่องานบริการ',
				result_title: 'รายการงานบริการภายใต้ศูนย์ทั้งหมด',
				no_data: 'ไม่พบข้อมูลรายการงานบริการภายใต้ศูนย์',
				item_col: 'ชื่อบริการ',
				item_col2: 'สถานะ',
				item_col3: 'Actions',
				delete_success: 'ลบข้อมูลบงานบริการเรียบร้อยแล้ว',
				create: {
					header: 'สร้างข้อมูลงานบริการ',
					save_fail: 'ไม่สามารถสร้างข้อมูลงานบริการได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการงานบริการภายใต้ศูนย์',
					header: 'จัดการข้อมูลงานบริการภายใต้ศูนย์',
					form_header: 'แก้ไขงานบริการ',
					form_sub_header: 'รายละเอียดงานบริการสำหรับแสดงผลบนเว็บไซต์',
					title_: 'ชื่อบริการ',
					title_hint: 'จำกัดตัวอักษร %{count}/250',
					description: 'รายละเอียดแบบย่อ',
					description_hint: 'จำกัดตัวอักษร %{count}/250',
					organization: 'ชื่อองค์กร',
					organization_hint: 'จำกัดตัวอักษร %{count}/100',
					media_url: 'Link ภายนอก',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงงานบริการต่อสมาชิกที่ใช้ระบบ',
					content: 'เนื้อหาบทความ',
					content_hint: 'กรอกรายละเอียดที่เกี่ยวข้องกับเนื้อหา...',
					save_success: 'บันทึกข้อมูลงานบริการใหม่เรียบร้อยแล้ว',
					image: {
						header: 'อัพโหลดภาพปก',
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 850x480'
					},
					validator: {
						title: '^กรุณากรอกชื่องานบริการ',
						description: '^กรุณากรอกรายละเอียดแบบย่อ',
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลงานบริการ'
					}
				}
			},
		},
		external_service: {
			contents: {
				title: 'รายการงานบริการหน่วยงานที่เกี่ยวข้อง',
				header: 'รายการงานบริการหน่วยงานที่เกี่ยวข้อง',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่องานบริการ',
				result_title: 'รายการงานบริการหน่วยงานที่เกี่ยวข้องทั้งหมด',
				no_data: 'ไม่พบข้อมูลรายการงานบริการหน่วยงานที่เกี่ยวข้อง',
				item_col: 'ชื่อบริการ',
				item_col2: 'สถานะ',
				item_col3: 'Actions',
				delete_success: 'ลบข้อมูลบงานบริการเรียบร้อยแล้ว',
				create: {
					header: 'สร้างข้อมูลงานบริการ',
					save_fail: 'ไม่สามารถสร้างข้อมูลงานบริการได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการงานบริการหน่วยงานที่เกี่ยวข้อง',
					header: 'จัดการข้อมูลงานบริการหน่วยงานที่เกี่ยวข้อง',
					form_header: 'แก้ไขงานบริการ',
					form_sub_header: 'รายละเอียดงานบริการสำหรับแสดงผลบนเว็บไซต์',
					title_: 'ชื่อบริการ',
					title_hint: 'จำกัดตัวอักษร %{count}/250',
					description: 'รายละเอียดแบบย่อ',
					description_hint: 'จำกัดตัวอักษร %{count}/250',
					organization: 'ชื่อองค์กร',
					organization_hint: 'จำกัดตัวอักษร %{count}/100',
					media_url: 'Link ภายนอก',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงงานบริการต่อสมาชิกที่ใช้ระบบ',
					content: 'เนื้อหาบทความ',
					content_hint: 'กรอกรายละเอียดที่เกี่ยวข้องกับเนื้อหา...',
					save_success: 'บันทึกข้อมูลงานบริการใหม่เรียบร้อยแล้ว',
					image: {
						header: 'อัพโหลดภาพปก',
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 850x480'
					},
					validator: {
						title: '^กรุณากรอกชื่องานบริการ',
						description: '^กรุณากรอกรายละเอียดแบบย่อ',
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลงานบริการ'
					}
				}
			},
		},
	},
	user: {
		groups: {
			title: 'รายการกลุ่ม',
			header: 'รายการกลุ่ม',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อกลุ่ม',
			result_title: 'รายการกลุ่มทั้งหมด',
			item_col: 'ชื่อกลุ่ม',
			item_col2: 'สถานะ',
			item_col3: 'Actions',
			delete_success: 'ลบข้อมูลกลุ่มเรียบร้อยแล้ว',
			no_data: 'ไม่พบรายการกลุ่มผู้ดูแลระบบ',
			create: {
				header: 'สร้างข้อมูลกลุ่ม',
				save_fail: 'ไม่สามารถสร้างข้อมูลกลุ่มได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการกลุ่ม',
				header: 'จัดการข้อมูลกลุ่ม',
				status_active: 'เปิดสาธารณะ',
				status_inactive: 'ซ่อนการมองเห็น',
				form_header: 'แก้ไขกลุ่ม',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับผู้ดูแล ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
				name: 'ชื่อกลุ่ม',
				name_hint: 'จำกัดตัวอักษร %{count}/100',
				description: 'รายละเอียดแบบย่อ',
				description_hint: 'จำกัดตัวอักษร %{count}/250',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงระดับต่อผู้ดูแลระบบ',
				save_success: 'บันทึกการข้อมูลกลุ่มใหม่เรียบร้อยแล้ว',
				validator: {
					name: '^กรุณากรอกชื่อกลุ่ม',
				},
				nav_config: {
					header: 'กำหนดสิทธิ์การใช้งานเมนู',
					sub_header: 'กำหนดการเข้าถึงเมนูต่างๆ ภายในเว็บไซต์ สำหรับสมาชิกที่อยู่ในกลุ่มนี้',
				},
				other_actions: {
					header: 'ดำเนินการอื่นๆ',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลผู้ดูแลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลกลุ่ม'
				}
			}
		},
		users: {
			title: 'รายการผู้ดูแลระบบ',
			header: 'รายการผู้ดูแลระบบ',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อผู้ดูแล',
			result_title: 'รายการผู้ดูแลทั้งหมด',
			item_col: 'ชื่อ-อีเมล',
			item_col2: 'ระดับ',
			item_col3: 'เข้าใช้งานล่าสุด',
			item_col4: 'วันที่เริ่มใช้งาน',
			item_col5: 'สถานะ',
			item_col6: 'Actions',
			delete_success: 'ลบข้อมูลผู้ดูแลเรียบร้อยแล้ว',
			no_data: 'ไม่พบรายการข้อมูลผู้ดูแลระบบ',
			filter: {
				section: 'Info',
				group_id: 'User group',
			},
			create: {
				header: 'สร้างข้อมูลผู้ดูแล',
				save_fail: 'ไม่สามารถสร้างข้อมูลผู้ดูแลได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				group_id: 'User group',
				group_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				email: 'อีเมล',
				password: 'รหัสผ่าน',
				password_hint: 'ต้องประกอบด้วยตัวอักษรภาษาอังกฤษพิมพ์เล็ก, ใหญ่, ตัวเลข และอักขระพิเศษ -+><?/\\@#$%^&',
				firstname: 'ชื่อ',
				lastname: 'นามสกุล',
				phone_placeholder: '081 234 5678',
				validator: {
					email: '^กรุณากรอกอีเมล',
					email_invalid: '^อีเมล์ไม่ถูกต้อง',
					password: '^กรุณากรอกรหัสผ่าน',
					password_length: '^ความยาวรหัสผ่านอย่างน้อย 6 ตัวอักษร',
					password_invalid: '^รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็ก,พิมพ์ใหญ่, ตัวเลข และอักขระพิเศษ -+><?/\\@#$%^& เท่านั้น',
					firstname: '^กรุณากรอกชื่อ',
					lastname: '^กรุณากรอกนามสกุล',
				},
			}
		},
		profile: {
			title: 'ตั้งค่า',
			tab: {
				default: 'ข้อมูลทั่วไป',
				profile: 'ข้อมูลส่วนตัว',
				group_id: 'User group',
				group_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				email: 'อีเมล',
				firstname: 'ชื่อ',
				lastname: 'นามสกุล',
				phone: 'เบอร์โทรศัพท์',
				phone_placeholder: '081 234 5678',
				save_success: 'บันทึกข้อมูลส่วนตัวเรียบร้อยแล้ว',
			},
			tab2: {
				default: 'เปลี่ยนรหัสผ่าน',
				change_password: 'เปลี่ยนรหัสผ่าน',
				password: 'รหัสผ่าน',
				confirm_password: 'ยืนยันรหัสผ่าน',
				save_success: 'บันทึกรหัสผ่านใหม่เรียบร้อยแล้ว',
				validator: {
					password: '^กรุณากรอกรหัสผ่าน',
					confirm_password: '^กรุณากรอกยืนยันรหัสผ่าน',
					password_not_match: 'รหัสผ่านไม่ตรงกัน'
				},
			},
		},
	},
};
